<template>
  <b-row>
    <b-col cols="6">
      <b-card title="Informasi">
        <validation-observer ref="simpleRules">
          <b-form>
            <form-input
              v-model="number"
              rules="required"
              label="Nomor"
              placeholder="Nomor Penggantian Unit"
            />
            <form-v-select
              ref="spb"
              v-model="spb"
              :dataurl="(userType == 'vendor'?'vendor/'+user.id+'/spb':'spb')+'?status=bapp_published,bapp_requested,bapp_approved,distributed&length=1000&sort[created_at]=desc'"
              label="Nomor SPB"
              rules="required"
              :item_text="'number'"
              placeholder="Select SPB"
              @input="changeSPB"
            />
            <form-date
              v-model="request_date"
              rules="required"
              label="Tgl Pengajuan"
              placeholder="Tgl Pengajuan"
            />
            <form-date
              v-model="realisation_date"
              rules="required"
              label="Tgl Realisasi"
              placeholder="Tgl Realisasi"
            />
            <form-input
              v-model="note"
              label="Keterangan"
              placeholder="Keterangan"
            />
            <hr>
            <b-form-group
              label-cols-md="4"
              label=""
            >
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="$router.go(-1)"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col cols="6">
      <b-card title="Kendaraan">
        <form-v-select
          ref="vehicle"
          v-model="vehicle"
          label="Nopol MT"
          placeholder="Select Kendaraan"
          :item_text="'number_plate'"
          :options="optionVehicle"
        />
        <form-input
          v-model="reason_note"
          label="Alasan"
          placeholder="Alasan Penggantian"
        />
        <hr>
        <b-form-group
          label-cols-md="4"
          label=""
        >
          <b-button
            style="float:right;"
            size="sm"
            variant="success"
            type="button"
            @click="addDetail()"
          >
            <span>Tambah</span>
          </b-button>
        </b-form-group>
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-card title="Detail Kendaraan">
        <b-table
          striped
          responsive
          :items="details"
          :fields="table_details"
        >
          <template #cell(_)="data">
            <span
              class="mr-1"
            >
              <b-link @click="confirmDelete(data)">
                <b-badge variant="danger">
                  <feather-icon icon="TrashIcon" />
                </b-badge>
              </b-link>
            </span>
          </template>
          <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton, BSpinner} from 'bootstrap-vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormFile from '@/views/base/form/FormFile.vue'
import FormDate from '@/views/base/form/FormDate.vue'
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BForm,
    BCard,
    BTable,
    BLink,
    BBadge, 
    BFormGroup,
    BFormSelect,
    BFormInput,
    baseSelect,
    FormInput,
    FormVSelect,
    BButton,
    BRow, 
    BCol,
    FormFile,
    FormDate,
    BSpinner
  },
  data() {
    return {
      user:null,
      userType:null,
      dataurl:"/vehicle-change",
      baseroute:"vehicle-change",
      title:"Mutasi",
      table_details:[
        { key: 'vehicle.number', label: 'Nopol'},
        { key: 'vehicle.variant.model', label: 'Varian' },
        { key: 'vehicle.year', label: 'Tahun'},
        { key: 'vehicle.color', label: 'Warna'},
        { key: 'reason_note', label: 'Alasan'},
        '_'
      ],
      optionBranch:[],
      optionVehicle:[],
      details:[],
      vehicle_detail:[],
      vehicle:null,
      spb:null,
      branch_destination:null,
      request_date:moment().format('Y-MM-D'),
      realisation_date:moment().format('Y-MM-D'),
      number:"",
      note:"",
      reason_note:"",
      loading:false
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute
  },
  mounted(){
    // this.fetchDataRegion()
    if(this.$route.params.id)
      this.fetchData()
  },
  methods: {
    fetchData(){
      this.$http.get(this.dataurl+'/'+this.$route.params.id).then(res=>{
        let data = res.data
        this.number = data.number
        this.note = data.note
        if(data.realisation_date)
          this.realisation_date = data.realisation_date
        this.request_date = data.request_date
        this.spb = data.spb
        this.details = data.details
        for(let i=0; i<this.details.length; i++){
          this.vehicle_detail.push({vehicle_id:this.details[i]['vehicle_id'], reason_note:this.details[i]['reason_note']})
        }
      })
    },
    fetchDataRegion(){
      this.$http.get('region').then(res=>{
        this.optionBranch = res.data.data
        this.$refs.spb.refreshData(this.optionBranch)
        this.$refs.branch_destination.refreshData(this.optionBranch)
      })
    },
    fetchDataVahicle(id){
      this.$http.get('spb/'+id+'/vehicle').then(res=>{
        this.optionVehicle = res.data.data
        this.$refs.vehicle.refreshData(this.optionVehicle)
      })
    },
    changeSPB(data){
      if(data == null){
        this.vehicle = null
      }else{
        this.fetchDataVahicle(data.id)
      }
      this.resetDetail()
    },
    confirmDelete(data){
      this.details.splice(data.index, 1)
      this.vehicle_detail.splice(data.index, 1)
    },
    resetDetail(){
      this.details = []
      this.vehicle_detail = []

    },
    addDetail() {
      if(this.vehicle == null ) 
        return this.$bvToast.toast('Please correct vehicle', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })

      if(this.reason_note == "" ) 
        return this.$bvToast.toast('Please correct alasan penggantian', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })

      this.details.push({
        vehicle:{
          vehicle_id:this.vehicle.vehicle_id,
          number: this.vehicle.number_plate,
          variant: this.vehicle.variant,
          year:this.vehicle.year,
          color:this.vehicle.color,
        },
        reason_note: this.reason_note
      })
      this.vehicle_detail.push({vehicle_id:this.vehicle.vehicle_id, reason_note:this.reason_note})
      this.vehicle = null
      this.reason_note = ""
    },
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if(this.details.length == 0)
            return this.$bvToast.toast('vehicle tidak boleh kosong', {
              title: 'Error',
              solid: true,
              variant:'danger'
            })

          let params = {
            number: this.number,
            request_date: this.request_date,
            realisation_date: this.realisation_date,
            spb_id: this.spb.id,
            note: this.note,
            details: this.vehicle_detail
          }
          
          this.loading = true
          let http = null
          if(this.$route.params.id)
            http = this.$http.put(this.dataurl+'/'+this.$route.params.id, params)
          else
            http = this.$http.post(this.dataurl, params)
            
          http.then(()=>{
            this.successSubmit()
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>